import React, { createContext, useState, useRef } from 'react';

export const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const [activeCategory, setActiveCategory] = useState('');
  const categoryRefs = useRef({});
  const navBarRef = useRef(null);
  const navButtonsRef = useRef({});

  const scrollToCategory = (category) => {
    setActiveCategory(category);
    const categoryRef = categoryRefs.current[category];
    if (categoryRef) {
      categoryRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <ScrollContext.Provider value={{ activeCategory, setActiveCategory, categoryRefs, navBarRef, navButtonsRef, scrollToCategory }}>
      {children}
    </ScrollContext.Provider>
  );
};
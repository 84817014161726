import React from "react";
import './index.css';
import StatusText from "./components/StatusText";

const Status = ({ statusNumder }) => {
  const statusMap = {
    0: "ГОТОВИМ",
    1: "ГОТОВИМ",
    2: "ГОТОВИМ",
    3: "ГОТОВИМ",
    4: "ГОТОВИМ",
    5: "ГОТОВЫ",
    6: "ПОДАЛИ",
    7: "ПОДАЛИ",
    8: "ПОДАЛИ",
  };

  return (
    <>
      <StatusText text={statusMap[statusNumder]} status={statusMap[statusNumder]} />
    </>
  );
};

export default Status;
// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import KitchenOrder from './KitchenOrder';
import NotFoundPage from './pages/NotFoundPage';
import { useParams } from 'react-router-dom';
// import MenuQr from './pages/MenuQr';
// import MenuNew from './pages/MenuNew';
// import MenuContainer from './pages/menu3/MenuContainer';
// import App2 from './pages/menu4/Menu4';
import App5 from './pages/menu5/Menu5';


function KitchenOrderWrapper() {
  const params = useParams();
  return <KitchenOrder nameLocation={params.nameLocation} baseOrder={params.baseOrder} />;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:nameLocation/:baseOrder" element={<KitchenOrderWrapper />} />
        <Route path="/" element={<div>Главная страница</div>} />
        {/* <Route path="/menu" element={<MenuQr/>} />
        <Route path='/menu-new' element={<MenuNew />} />
        <Route path='/menu2' element={<MenuContainer />} />
        <Route path='/menu4' element={<App2 />} /> */}
        <Route path='/menu5' element={<App5 />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
import React from 'react';
import './../index.css';

const OrderNumber = ({ number }) => {
  // Ensure the number is always 4 digits long
  const paddedNumber = String(number).padStart(4, '0');

  return (
    <div className="order-number-container">
      {paddedNumber.split('').map((digit, index) => (
        <div key={index} className="digit-block">
          {digit}
        </div>
      ))}
    </div>
  );
};

export default OrderNumber;
import React, { useEffect, useState, useContext } from 'react';
import './App5.css';
import { ScrollContext, ScrollProvider } from './ScrollContext'; // Укажите правильный путь к вашему контексту

function App5Content() {
  const [menuData, setMenuData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeCategory, setActiveCategory, categoryRefs, navBarRef, navButtonsRef, scrollToCategory } = useContext(ScrollContext);

  useEffect(() => {
    fetch('https://back.soursecode.ru/menu/api-menu')
      .then(response => response.json())
      .then(data => {
        setMenuData(data.itemCategories);
        const uniqueCategories = data.itemCategories.map(category => category.name);
        setCategories(uniqueCategories);
        setActiveCategory(uniqueCategories[0]);
        setLoading(false);
      })
      .catch(error => {
        console.error('Ошибка при получении данных:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (categories.length > 0 && activeCategory === '') {
      setActiveCategory(categories[0]);
    }
  }, [categories, activeCategory]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = Object.values(categoryRefs.current);

      for (let section of sections) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 80 && rect.bottom >= 0) {
          setActiveCategory(section.id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuData]);

  const autoScrollNav = (scrollOffset = 100) => {
    const activeButton = navButtonsRef.current[activeCategory];
    if (activeButton) {
      const navBar = navBarRef.current;
      const buttonRect = activeButton.getBoundingClientRect();
      const navBarRect = navBar.getBoundingClientRect();

      if (buttonRect.right > navBarRect.right || buttonRect.left < navBarRect.left) {
        if (buttonRect.right > navBarRect.right) {
          navBar.scrollLeft += buttonRect.right - navBarRect.right + scrollOffset;
        } else if (buttonRect.left < navBarRect.left) {
          navBar.scrollLeft -= navBarRect.left - buttonRect.left + scrollOffset ;
        }
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      autoScrollNav();
    }, 500); // Добавляем небольшую задержку, чтобы убедиться, что скролл успевает завершиться

    return () => clearTimeout(timeoutId);
  }, [activeCategory]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <h1>QR меню</h1>
      <nav ref={navBarRef} className="nav-bar">
        <ul>
          {categories.map(category => (
            <li key={category}>
              <button
                ref={el => navButtonsRef.current[category] = el}
                onClick={() => scrollToCategory(category)}
                className={category === activeCategory ? 'active' : ''}
              >
                {category}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <div className="menu-items">
        {menuData.map(category => (
          <section key={category.id} id={category.name} ref={el => categoryRefs.current[category.name] = el} className="category-section">
            <h2>{category.name}</h2>
            <div className="item-grid">
              {category.items.map(item => (
                <article key={item.itemId} className="menu-item">
                  <div className="item-image-container">
                    <img src={item.itemSizes[0].buttonImageUrl} alt={item.name} />
                  </div>
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                  <p>Цена: {item.itemSizes[0].prices[0].price} руб.</p>
                  <div className="nutritions">
                    {item.itemSizes[0].nutritions.map((nutrition, index) => (
                      <div key={index}>
                        <p>Жиры: {nutrition.fats} г</p>
                        <p>Белки: {nutrition.proteins} г</p>
                        <p>Углеводы: {nutrition.carbs} г</p>
                        <p>Энергия: {nutrition.energy} ккал</p>
                      </div>
                    ))}
                  </div>
                </article>
              ))}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
}

function App5() {
  return (
    <ScrollProvider>
      <App5Content />
    </ScrollProvider>
  );
}

export default App5;
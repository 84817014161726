// src/KitchenOrder.jsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Status from "./Status";
import OrderNumber from "./components/OrderNumber";
import "./index.css";
import calipsoLogo from "./assets/calipso_logo.svg";

const KitchenOrder = ({nameLocation, baseOrder}) => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [previousStatuses, setPreviousStatuses] = useState({});
  const [itemStatuses, setItemStatuses] = useState({});

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(
          `https://back.soursecode.ru/${nameLocation}/${baseOrder}`
        );
        setOrder(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();

    const intervalId = setInterval(() => {
      fetchOrder(); // Запрос каждые 5 секунд
    }, 5000);

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [orderId]);

  useEffect(() => {
    if (order) {
      const newItemStatuses = { ...itemStatuses };
      const newPreviousStatuses = { ...previousStatuses };

      order.Items.forEach((item) => {
        const kitchenName = item.KitchenName;
        const itemId = item.id; // Предполагается, что у каждого элемента есть уникальный идентификатор

        if (!newItemStatuses[itemId] || newItemStatuses[itemId] !== item.ProcessingStatus) {
          newItemStatuses[itemId] = item.ProcessingStatus;
        }

        const itemsInKitchen = order.Items.filter(
          (i) => i.KitchenName === kitchenName
        );

        const allSameStatus = itemsInKitchen.every(
          (i) => newItemStatuses[i.id] === newItemStatuses[itemsInKitchen[0].id]
        );

        if (allSameStatus) {
          newPreviousStatuses[kitchenName] = newItemStatuses[itemsInKitchen[0].id];
        }
      });

      setItemStatuses(newItemStatuses);
      setPreviousStatuses(newPreviousStatuses);
    }
  }, [order]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!order) return <div>No data found</div>;

  // Group items by KitchenName
  const groupedItems = order.Items.reduce((acc, item) => {
    if (!acc[item.KitchenName]) {
      acc[item.KitchenName] = [];
    }
    acc[item.KitchenName].push(item);
    return acc;
  }, {});

  return (
    <div className="layout-wrapper">
      <div className="status-wrapper">
        <img src={calipsoLogo} alt="Calipso" className="logo" />
      </div>
      <div className="status-wrapper">
        <h1>НОМЕР ЗАКАЗА</h1>
        <OrderNumber number={order.Number} />
      </div>

      {Object.keys(groupedItems).map((kitchenName, index) => {
        const statusToShow = previousStatuses[kitchenName];

        return (
          <div className="status-wrapper" key={index}>
            <h1>{kitchenName}</h1>
            <Status statusNumder={statusToShow} />
          </div>
        );
      })}
    </div>
  );
};

export default KitchenOrder;